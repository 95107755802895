<template>
  <div>
    <div class="flex flex-row w-full">
      <div class="w-3/4">
        <h1
          class="text-xl font-medium font-headers mb-3 font-bold text-grey-dark-2"
        >
          <span class="text-grey-medium-2 font-normal">
            Companies / {{ currentCompany.name }} / People /
          </span>
          <span v-clamp="40">{{
            selectedUser.firstName + " " + selectedUser.lastName
          }}</span>
        </h1>
      </div>
    </div>
    <div class="p-2 bg-white rounded shadow">
      <div class="mb-2.5 flex flex-row items-center flex-grow">
        <p class="text-mdl font-bold font-headers text-grey-light">
          User Details
        </p>
        <div class="flex flex-row flex-grow justify-end">
          <Button
            v-if="disableResendButton"
            type="secondary"
            size="medium"
            text="Re-Send Platform Invitation"
            @click.native="resendPlatformInvitation"
            class="mr-2"
            data-testid="cancel-button"
          />
          <Button
            text="Save"
            type="primary"
            size="medium"
            :disabled="!!roleError"
            @click.native="editUser"
            data-testid="save-button"
          />
        </div>
      </div>
      <div class="p-2 w-1/2">
        <div class="flex flex-grow mb-3 space-x-2">
          <InputField
            label="First Name"
            class="flex-1"
            :onChange="setFirstName"
            :defaultValue="firstName"
            :isTextOk="
              !$v.firstName.$dirty ? null : $v.firstName.required ? null : false
            "
            v-model="$v.firstName.$model"
            errorText="First name is required"
            data-testid="first-name-field"
          />
          <InputField
            label="Last Name"
            class="flex-1"
            :onChange="setLastName"
            :defaultValue="lastName"
            :isTextOk="
              !$v.lastName.$dirty ? null : $v.lastName.required ? null : false
            "
            errorText="Last name is required"
            data-testid="last-name-field"
          />
        </div>
        <InputField
          label="Email"
          class="mb-3"
          :onChange="setEmail"
          :defaultValue="email"
          :isTextOk="!$v.email.$dirty ? null : $v.email.emailValidator ? null : false"
          errorText="Email is required"
          data-testid="email-field"
        />
        <InputField
          label="Job Title"
          :onChange="setJobTitle"
          :defaultValue="jobTitle"
          data-testid="job-title-field"
        />

        <div class="mt-3">
          <p class="text-sm-2 text-grey-light mb-1">System Roles</p>
          <div class="flex flex-row space-x-4">
            <div
              @click="switchRole('employeeRole')"
              class="flex flex-row items-center"
            >
              <Checkbox
                class="cursor-pointer"
                :active="!!employeeRole"
                data-testid="employee-checkbox"
              />
              <p class="pl-1 cursor-pointer">
                Employee
              </p>
            </div>
            <div
              @click="switchRole('managerRole')"
              class="flex flex-row items-center"
            >
              <Checkbox
                class="cursor-pointer"
                :active="!!managerRole"
                data-testid="manager-checkbox"
              />
              <p class="pl-1 cursor-pointer">
                Manager
              </p>
            </div>
            <div
              @click="switchRole('adminRole')"
              class="flex flex-row items-center"
            >
              <Checkbox
                class="cursor-pointer"
                :active="!!adminRole"
                data-testid="admin-checkbox"
              />
              <p class="pl-1 cursor-pointer">
                Admin
              </p>
            </div>
          </div>
          <span class="text-red mt-2 text-sm-2">
            {{ roleError }}
          </span>
        </div>
      </div>
    </div>
    <div class="p-2 mt-2 bg-white rounded shadow">
      <p class="mb-1.5 mt-1 text-mdl font-bold font-headers text-grey-light">
        Status
      </p>
      <p class="px-2 text-grey-dark-1 text-mdh mb-1.5">
        A user can be deactivated and reactivated at any time. Deactivating a
        user does not remove their data for past assessments.
      </p>
      <div class="px-2 mb-2 flex flex-grow items-center justify-between">
        <p class="text-md" :class="statusColor">
          {{ status }}
        </p>
        <button
          @click="toggleUserStatus"
          :class="
            selectedUser.status.toString() !== '0'
              ? 'text-red text-md font-semibold'
              : 'text-sm-2 font-medium text-blue-dark-1 px-2 py-1 bg-blue-light-1 rounded'
          "
        >
          {{
            selectedUser.status.toString() !== "0"
              ? "Deactivate User"
              : "Activate User"
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import InputField from "../../../components/InputField/InputField.vue";
import { required } from "vuelidate/lib/validators";
import { emailValidator } from '../../AdminAssessments/utils/emailValidator';

export default {
  name: "CompanyIndividualUserView",
  components: { InputField },

  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    email: {
      required,
      emailValidator,
    },
  },
  computed: {
    ...mapState({
      selectedUser: (state) => state.companies.selectedUser,
      currentCompany: (state) => state.companies.currentCompany,
    }),
    firstName: {
      get() {
        return this.selectedUser.firstName;
      },
      set(value) {
        this.selectedUser.firstName = value;
      },
    },
    lastName: {
      get() {
        return this.selectedUser.lastName;
      },
      set(value) {
        this.selectedUser.lastName = value;
      },
    },
    email: {
      get() {
        return this.selectedUser.email;
      },
      set(value) {
        this.selectedUser.email = value;
      },
    },
    jobTitle: {
      get() {
        return this.selectedUser.jobTitle;
      },
      set(value) {
        this.selectedUser.jobTitle = value;
      },
    },
    employeeRole: {
      get() {
        return this.selectedUser.roles?.find((el) => el.id === 4);
      },
      set() {
        if (this.employeeRole) {
          let index = this.selectedUser.roles.indexOf(this.employeeRole);
          this.selectedUser.roles.splice(index, 1);
        } else this.selectedUser.roles.push({ id: 4, name: "Employee" });
      },
    },
    managerRole: {
      get() {
        return this.selectedUser.roles?.find((el) => el.id === 3);
      },
      set() {
        if (this.managerRole) {
          let index = this.selectedUser.roles.indexOf(this.managerRole);
          this.selectedUser.roles.splice(index, 1);
        } else this.selectedUser.roles.push({ id: 3, name: "Manager" });
      },
    },
    adminRole: {
      get() {
        return this.selectedUser.roles?.find((el) => el.id === 2);
      },
      set() {
        if (this.adminRole) {
          let index = this.selectedUser.roles.indexOf(this.adminRole);
          this.selectedUser.roles.splice(index, 1);
        } else this.selectedUser.roles.push({ id: 2, name: "CompanyAdmin" });
      },
    },
    roleError() {
      if (!this.employeeRole && !this.managerRole && !this.adminRole) {
        return "At least one role needs to be selected";
      } else return null;
    },
    disableResendButton() {
      return this.selectedUser.status.toString() !== "0";
    },
    status() {
      switch (this.selectedUser.status.toString()) {
        case "0":
          return "Inactive";
        case "1":
          return "Active";
        case "2":
          return "Invited";
        case "3":
          return "Not invited";
        default:
          break;
      }
    },
    statusColor() {
      switch (this.selectedUser.status.toString()) {
        case "0":
          return "text-red";
        case "1":
          return "text-green-2";
        case "2":
          return "text-yellow-medium";
        case "3":
          return "text-grey-medium-2";
        default:
          break;
      }
    },
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
      edit: "people/edit",
      resendInvitation: "companies/resendInvitation",
      changeUserStatus: "companies/changeUserStatus",
    }),
    async resendPlatformInvitation() {
      await this.resendInvitation({ id: this.selectedUser.id });
    },
    setFirstName(value) {
      this.firstName = value;
      this.$v.firstName.$touch();
    },
    setLastName(value) {
      this.lastName = value;
      this.$v.lastName.$touch();
    },
    setEmail(value) {
      this.email = value;
      this.$v.email.$touch();
    },
    setJobTitle(value) {
      this.jobTitle = value;
    },
    switchRole(key) {
      this[key] = this[key];
    },
    async editUser() {
      var roles = [this.employeeRole, this.managerRole, this.adminRole]
        .filter((item) => !!item?.id)
        .map((item) => item.id);

      const payload = {
        id: this.selectedUser.id,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        jobTitle: this.jobTitle,
        organizationId: this.currentCompany.id,
        roles,
      };
      await this.edit({ payload });
    },
    async toggleUserStatus() {
      if (this.selectedUser.status.toString() !== "0")
        this.openModal({
          modal: "deactivateCustomerUserModal",
          props: { userId: this.selectedUser.id },
        });
      else
        await this.changeUserStatus({
          userId: this.selectedUser.id,
          isActive: true,
        });
    },
  },
};
</script>
